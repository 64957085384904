import React from 'react'
import Slider from "react-slick";
import Testimonial from '../common/Testimonial'

const Testimonials = ({ listOfTestimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  }

  const renderTestimonials = testimonials => {
    return testimonials.map(({ node: testimonial }, index) => (
      <div key={index}>
        <Testimonial testimonial={testimonial} />
      </div>
    ))
  }

  return (
    <section id="testimonials-clients" className="testimonials testimonials-home container">
      <div className="row">
        <div className="col">
          <h1 className="text-center text-lowercase testimonials-title mt-0">
          Les avis de nos clients
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-8 offset-md-2 pb-2 pb-sm-0">
          <Slider {...settings}>
            {renderTestimonials(listOfTestimonials)}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
