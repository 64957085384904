import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Graph = () => (
  
  <section id="graph">
    <Container>
      <Row>
        <Col>
          <div
            className="seo-h1 text-center text-lowercase mt-0 mb-5"
          >
            <h1 className="m-0">
            Ok, et maintenant?
            </h1>
          </div>
          <ol className="graph">
            <li className="graph-item">
              <span>1</span>
              <div>
               Nous allons lire votre message.<br/>
                Merci!
              </div>
            </li>
            <li className="graph-item">
              <span>2</span>
              <div>
                Nous allons répondre<br/>
                en six heures
              </div>
            </li>
            <li className="graph-item">
              <span>3</span>
              <div>
              Nous allons fixé un rendez-vous une <br/>
              demi heure avec<br/>
              l’un de nos experts
              </div>
            </li>
            <li className="graph-item">
              <span>4</span>
              <div>
              Vous allez recevoir une offre<br/>
              en cinq jours ouvrables
              </div>
            </li>
            <li className="graph-item">
              <span>5</span>
              <div>
                Tout s’est passé bien?<br/>
                Commençons!
              </div>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </section>
)

export default Graph
