import React from 'react'
import Link from '../common/Link'
import horizontalDiagram from '../../assets/images/process_diagram_hor.svg'
import verticalDiagram from '../../assets/images/process_diagram_vert.svg'

const ProcessDiagram = () => (
  <section id="process" className="bg-dark">
    <div className="container text-center">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-12 offset-md-0">
          <h1 className="text-lowercase">
            Nous <span className="text-primary">suivons les processus</span>
          </h1>
          <div className="lead">
            <h1 className="seo-strong">
              <Link to="/agile-lifecycle-management/" className="link-unstyled">
                Agile Project Lifecycle Management{' '}
              </Link>
            </h1>
            est une méthode efficace de gestion des processus au cours du développement de logiciel. Faisant parti d’un processus, il est plus facile de surveiller le projet et d’évaluer les performances.
          </div>
          <p style={{ marginTop: '2rem' }}>
            <Link
              to="/agile-lifecycle-management/"
              className="btn btn-outline-primary"
            >
              <span className="fas fa-info-circle" /> En savoir plus sur Agile PLM
            </Link>
          </p>
        </div>
      </div>
      <img
        src={horizontalDiagram}
        alt="SCRUM Process Diagram"
        className="process-diagram img-fluid d-none d-md-block"
      />
      <img
        src={verticalDiagram}
        alt="SCRUM Process Diagram"
        className="process-diagram img-fluid d-block d-md-none"
      />
    </div>
  </section>
)

export default ProcessDiagram
