import React from 'react'
import Link from '../common/Link';
import imageOutsourcingServices from '../../assets/images/services_outsourcing.png'
import imageMobileServices from '../../assets/images/services_mobile.png'
import imageWebServices from '../../assets/images/services_web.png'

import { Card, Col, Container, Row } from 'reactstrap'

const Services = () => (
  <section id="services">
    <Container>
      <Row>
        <Col>
          <h1 className="text-center text-lowercase services-title">
            <strong>
            Solutions web <span className="text-nowrap">& mobiles</span>
            </strong>
            <br />
            pour votre projet suivant
          </h1>
        </Col>
      </Row>
    </Container>
    <div className="target-card-deck">
      <hr className="rainbow m-0 hr-bg" />
      <Container>
        <Row>
          <Col sm="12" md="4">
            <Card className="target-card target-card-3">
              <div className="card-header text-center">
                <div className="card-title">
                  <h2>Sociétés IT</h2>
                  <p className="target-card-slogan">Faites évaluer votre équipe</p>
                  <p>
                    <em>Embauchez des programmeurs d'application sans frontières</em>
                  </p>
                </div>
              </div>
              <div className="card-block d-flex align-items-center">
                <div className="card-text flex-grow-1">
                  <Row>
                    <Col sm="12">
                      <div className="d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-sm-start">
                        <img
                          src={imageOutsourcingServices}
                          alt="Outsourcing development"
                          style={{ height: '75px' }}
                        />
                        <div className="text-left ml-3 flex-grow-1">
                          <p className="seo-h4"><a className="card-link-title" href="/services/poland-software-outsourcing/">Externalisation IT</a></p>
                          <p>
                            <Link
                              id="forIntersectionObserver"
                              to="/services/poland-software-outsourcing/"
                              className="btn btn-outline-success btn-sm"
                              rel="nofollow"
                            >
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm="12" md="8">
            <Card className="target-card target-card-1">
              <div className="card-header text-center">
                <div className="card-title">
                  <h2>Startups</h2>
                  <p className="target-card-slogan">Attestez la validité de votre concept</p>
                  <p>
                    <em>Nous fournissons MVP en 12-16 semaines avec le plein soutien de notre équipe</em>
                  </p>
                </div>
              </div>
              <div className="card-block d-flex align-items-center">
                <div className="card-text flex-grow-1">
                  <Row>
                    <Col sm="6">
                      <div className="d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-sm-start">
                        <img
                          src={imageMobileServices}
                          alt="Mobile development"
                          style={{ height: '75px' }}
                        />
                        <div className="text-left ml-3 flex-grow-1">
                          <p className="seo-h4"><a className="card-link-title" href="/services/mobile-app-development-poland/">Développement des applications mobiles en Pologne</a></p>
                          <p>
                            <Link
                              to="/services/mobile-app-development-poland/"
                              className="btn btn-outline-extra1 btn-sm"
                              rel="nofollow"
                            >
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="d-flex flex-row-reverse flex-sm-row justify-content-between justify-content-sm-start">
                        <img
                          src={imageWebServices}
                          alt="Web development"
                          style={{ height: '75px' }}
                        />
                        <div className="text-left ml-3 flex-grow-1">
                          <p className="seo-h4"><a className="card-link-title" href="/services/web-app-development-poland/">Développement des applications web en Pologne</a></p>
                          <p>
                            <Link
                              to="/services/web-app-development-poland/"
                              rel="nofollow"
                              className="btn btn-outline-info btn-sm"
                            >
                              En savoir plus
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)

export default Services
