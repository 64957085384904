import React from 'react'
import NavBar from '../components/common/NavBar'
import Intro from '../components/home/Intro'
import video from '../assets/images/intro.mp4'
import Services from '../components/home/Services'
import Technologies from '../components/common/Technologies'
import CtaInfoPack from '../components/home/CtaInfoPack'
import SelectedSingleProject from '../components/home/SelectedSingleProject'
import ProcessDiagram from '../components/home/ProcessDiagram'
import Testimonials from '../components/home/Testimonials'
import Divider from '../components/common/Divider'
import RecommendedArticles from '../components/home/RecommendedArticles'
import ContactBottom from '../components/contact/ContactBottom'
import Infographic from '../components/home/Infographic'
import Graph from '../components/home/Graph'
import { graphql } from 'gatsby'

const Index = ({ data, location }) => {
  const { legalName } = data.info
  const { city, street, country, url } = data.info.address.office
  const { email, phone, phoneClean, skype, skypeName } = data.info.contact
  const testimonials = data.testimonialsHome.edges
  const technologies = data.technologies_home.edges
  const workTime = data.info.contact.workTimeUTC
  const selectedProject = data.selected_project
  const articles = data.allBlogJson.edges
  const image = data.bgImage.resolutions
  const mailingConfig = data.mailing_info_home
  const postsImages = [data.postImg1, data.postImg2]

  return (
    <>
      <NavBar fixedPosition transparentBackground location={location} />
      <Intro bgImage={image} video={video} />
      <Services />
      <Technologies listOfTechnologies={technologies} />
      <Divider />
      <Testimonials listOfTestimonials={testimonials} />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTime}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
        mailingConfig={mailingConfig}
        home
      />
      <Divider fullWidth />
      <Graph />
      <ProcessDiagram />
      <Infographic />
      <SelectedSingleProject project={selectedProject} />
      <CtaInfoPack
        infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`}
      />
      <RecommendedArticles
        listOfArticles={articles}
        newsletterUrl={`${process.env.GATSBY_MAILING_URL}/api/newsletter`}
        postsImages={postsImages}
      />
    </>
  )
}

export default Index

export const query = graphql`
  query dataQuery {
    info: dataJson {
      ...Contact
    }
    bgImage: imageSharp(fluid: { originalName: { regex: "/intro_bg.jpg/" } }) {
      resolutions(width: 1920, height: 1080, quality: 80) {
        ...GatsbyImageSharpResolutions
      }
    }
    technologies_home: allTechnologiesJson {
      ...Technologies
    }
    selected_project: projectsYaml(title: { eq: "Grasp" }) {
      ...SelectedSingleProject
    }
    testimonialsHome: allTestimonialsClientsJson(
      filter: { displayIn: { eq: "home" } }
    ) {
      edges {
        node {
          name
          company
          role
          message
          avatar {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          social {
            url
            icon
          }
        }
      }
    }
    allBlogJson {
      edges {
        node {
          title
          excerpt
          url
        }
      }
    }
  }
`
